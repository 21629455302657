@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Century Gothic", sans-serif;
  src: url("/public/Fonts/Century Gothic.ttf") format("truetype");
}

* {
  font-family: "Century Gothic", sans-serif;
}

.flex-center {
  @apply flex justify-center items-center;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #cccccc;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.hide-scroll::-webkit-scrollbar {
  height: 0.5px;
  width: 0.5px;
  display: none !important;
}

/* Track */
.hide-scroll ::-webkit-scrollbar-track {
  background: #000000;
  display: none;
}

/* Handle */
.hide-scroll::-webkit-scrollbar-thumb {
  background: #000000;
  display: none;
}

/* Handle on hover */
.hide-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.Toastify__toast {
  background: #444444;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.cms-desc-pre {
  white-space: pre-line;
}
.image-gallery-left-nav svg, .image-gallery-right-nav svg{
  height: 50px !important;
  width: 35px !important;
}
.image-gallery-left-nav, .image-gallery-right-nav {
  padding: 50px 0px;
}

.gold-color-text {
  color: #daa520;
}

.pagination_container {
  text-align: center;
}
.pagination {
  display: inline-flex;
  justify-content: center;
  margin-block: 15px;

  border: 1px solid #ccc;
  border-radius: 10px;
}
.pagination li {
  width: 40px;
  height: 40px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Century Gothic", sans-serif;
  border-right: 1px solid #ccc;
  color: #616161;
}
.pagination li:last-child {
  border-right: 0;
}
.pagination li.active {
  background-color: #f1c40f;
  color: #121212;
}